import { useCallback, useMemo } from 'react';
import { useFormikContext } from 'formik';

/**
 * https://formik.org/docs/api/useFormikContext
 * Returns variables and functions that extend formik's functionality to
 * suit our needs
 *
 * @return {object}
 */
const useFormikExtensions = () => {
  const {
    getFieldProps,
    getFieldMeta,
    values,
    submitCount,
    isValid,
    ...formikBag
  } = useFormikContext();

  /**
   * Returns object in the shape of error props for our InputError component
   *
   * @param {string} field - The name of the field in formik
   * @return {object}
   */
  const getFieldErrorProps = useCallback((field: string) => {
    const { error, touched, value, initialValue } = getFieldMeta(field);

    return {
      error: (Boolean(error) && (value !== initialValue || touched)),
      errorMessage: error,
    };
  }, [getFieldMeta]);

  /**
   * Returns all of the formik props together with our error props
   *
   * @param {string} field - The name of the field in formik
   * @return {object}
   */
  const getFieldPropsWithError = useCallback((field: string) => ({
    ...getFieldProps(field),
    ...getFieldErrorProps(field),
  }), [getFieldErrorProps, getFieldProps]);

  /**
   * Returns whether or not field has a visible formik error
   *
   * @param {string} field - The name of the field in formik
   * @return {boolean}
   */
  const hasVisibleFieldError = useCallback((field: string) => {
    const { error, touched } = getFieldMeta(field);

    return touched && Boolean(error);
  }, [getFieldMeta]);

  /**
   * It returns whether or not there is a visible field error in the form
   *
   * @return {boolean}
   */
  const hasVisibleErrors = useMemo(() => (
    Object.keys(values).some(field => hasVisibleFieldError(field))
  ), [values, hasVisibleFieldError]);

  /**
   * Returns the current visible errors in the form.
   *
   * @return {boolean}
   */
  const getVisibleFieldErrors = useCallback(() => (
    Object.keys(values).filter(field => hasVisibleFieldError(field))
  ), [values, hasVisibleFieldError]);

  /**
   * Returns the first visible error in the form.
   *
   * @return {boolean}
   */
  const getFirstVisibleFieldError = useCallback(() => {
    const visibleFieldErrors = getVisibleFieldErrors();

    return visibleFieldErrors?.[0] ?? null;
  }, [getVisibleFieldErrors]);

  /**
   * Scroll user to the given field
   *
   * @param {string} field - the name of the field we want to scroll to
   * @param {object} options - the scroll into view options
   */
  const scrollToField = useCallback((
    field: string,
    options: ScrollIntoViewOptions = { behavior: 'smooth', block: 'center' },
  ) => {
    let element: HTMLElement = document.querySelector(`[name="${field}"]`)
      || document.querySelector('[aria-invalid="true"]');

    // if the element is hidden, we cannot scrollIntoView to it.
    // Instead, scroll to the parent element
    if (element && element.offsetParent === null) {
      element = element.parentElement;
    }

    if (element) element.scrollIntoView(options);
  }, []);

  /**
   * Scroll user to the given field
   *
   * @param {object} options - the scroll into view options
   */
  const scrollToFirstError = useCallback((
    options: ScrollIntoViewOptions = { behavior: 'smooth', block: 'center' },
  ) => {
    if (hasVisibleErrors) {
      const firstError = getFirstVisibleFieldError();

      if (firstError) scrollToField(firstError, options);
    }
  }, [hasVisibleErrors, getFirstVisibleFieldError, scrollToField]);

  return {
    getFieldErrorProps,
    getFieldMeta,
    getFieldProps,
    getFieldPropsWithError,
    getFirstVisibleFieldError,
    getVisibleFieldErrors,
    hasVisibleErrors,
    hasVisibleFieldError,
    isValid,
    scrollToField,
    scrollToFirstError,
    submitCount,
    values,
    ...formikBag,
  };
};

export default useFormikExtensions;
